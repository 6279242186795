import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from 'gatsby';
import { Container } from '../blocks';
import SEO from '../components/seo';
export const query = graphql`
  query {
    file(name: { eq: "confused-travolta" }) {
      extension
      publicURL
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`404 Page not found`}</h1>
    <SEO title="404" mdxType="SEO" />
    <img src={props.data.file.publicURL} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      